.gallery {
  margin-top: 5em;
  margin-bottom: 4.125em;

  @include breakpoint(XS) {
    margin-top: 0;
  }
  &.grid {
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    grid-auto-rows: 8em;

    @include breakpoint(XS) {
      column-count: 1;
      display: block;
      column-count: auto;
    }
    .grid-item {
      display: block;
      position: relative;
      margin: 1em;
      width: calc(100% - 2em);
      grid-row-end: span 2;
      overflow: hidden;

      @include breakpoint(XS) {
        height: 14em;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @extend %slow-transition;
      }
      &:after {
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @extend %transition;
      }
      &.overlay-active {
        .grid-item-caption {
          opacity: 1;
        }
        &:hover {
          figure {
            .grid-item-caption {
              background: rgba(#000, 0.3);
            }
          }
        }
      }
      &:hover {
        .grid-item-caption {
          opacity: 1;
        }
        .arrow-icon {
          transform: translateX(0.25em);
        }
        img {
          transform: scale(1.05);
        }
      }
      figure {
        margin-bottom: 0;

        .grid-item-caption {
          @extend %transition;
          background: rgba(#000, 0.6);
          text-align: center;
          color: #fff;
          opacity: 0;
          position: absolute;
          padding: 1em;
          top: 0;
          height: 100%;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .caption-text {
          margin-top: 1rem;
          font-family: $font-stack-headings;
          font-size: 1em;
          position: relative;
          width: 100%;
          z-index: 1;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 0.125em;
        }
        .expand-icon,
        .arrow-icon {
          position: relative;
          z-index: 1;
          padding: 0rem;
          // background: $color-gold;

          svg {
            display: block;
          }
        }
        .arrow-icon {
          padding: 0;
          border: 0;
          @extend %nav-transition;
        }
      }
      &.category-target.fade-out {
          opacity: 0;
      }
      &.category-target.filtered {
        display: none;
      }
    }
  }
}

.no-cssgrid {
  .gallery.grid {
    display: flex;
    flex-wrap: wrap;

    .grid-item {
      height: 20em;
      width: calc(30%);
      opacity: 1;

      @include breakpoint(S) {
        width: calc(50% - 1em - 1px);
      }
      @include breakpoint(XS) {
        width: calc(100% - 1em);
      }
    }
  }
}

.in-page-nav.gallery-nav {
  @include breakpoint(XS) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }
}

.gallery-wrapper {
  margin-bottom: 5em;
}

.gallery-sidebar-edge-vert {
  position: absolute;
  top: 0;
  right: calc(100% - 10px);
  display: block;
  z-index: 2;

  @include breakpoint(S) {
    display: none;
  }
}

.gallery-sidebar-edge-horz {
  display: none;
  position: absolute;
  bottom: calc(100% - 12px);
  left: 0;
  width: 100%;
  z-index: 2;

  @include breakpoint(S) {
    display: block;
  }
}

// Gallery - Front Styles

.page-template-page-gallery-front {
    .banner {
        @include breakpoint(XS) {
            margin-bottom: 2.5rem;
        }
    }
    footer {
        @include breakpoint(XS) {
            padding-bottom: 4rem;
        }
    }
}