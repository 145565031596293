.faqs {
    position: relative;
    z-index: 2;
    padding-top: 6rem;

    .faqs-sidebar {
        float: left;
        width: 28em;
        padding-right: 7rem;
        z-index: 2;
        top: 10rem;

        @include breakpoint(M) {
            width: 22rem;
            padding-right: 1rem;
        }
        @include breakpoint(XS) {
            display: none;
        }
    }
    .faqs-nav-wrapper {
        background: $color-purple;
        margin-bottom: 3em;
        padding: 1.5rem;
    }
    .faq-nav {
        margin: 0;
        padding: 0.5rem 0 0 0;
        background: $color-purple;
        z-index: 2;
        position: relative;

        li {
            padding: 0 0 0.5rem 0;
            margin: 0.5rem 0;
            border-bottom: 1px solid rgba(#fff, 0.5);

            &.active {
                border-bottom: 1px solid $color-active;
            }
            &:before {
                display: none;
            }
            &:last-child {
                margin: 0;
            }
        }
        a {
            display: block;
            font-weight: 700;
            color: #fff;

            span {
                margin-right: 1rem;
            }
            &:hover,
            &.active {
                color: $color-active;
                padding-left: 0.5rem;
            }
        }
    }
    .faqs-content {
        float: right;
        width: calc(100% - 28rem);
        padding-left: 1rem;

        @include breakpoint(M) {
            width: calc(100% - 24rem);
        }
        @include breakpoint(XS) {
            width: 100%;
            margin-top: 3rem;
            padding-left: 0;
        }
    }
}

.faqs-search {
    padding: 0 0 0 3rem;
    position: relative;
    background: #fff;
    margin-bottom: 2.25rem;

    svg {
        position: absolute;
        left: 0.5625rem;
        top: calc(50% - 16px);
        margin: 0;
        z-index: 3;
    }
    input {
        border: 0;
        padding: 1rem 1rem 1rem 0;
    }
}

.faqs-notification {
    margin-bottom: 2rem;
    display: none;

    @include breakpoint(XS) {
        text-align: center;
    }
    &.active {
        display: flex;
    }
    .results-for {
        padding: 1rem;
        background: rgba($color-gold, 0.15);
        color: $color-gold;

        @include breakpoint(XS) {
            flex-grow: 1;
        }
    }
    .filter-count {
        padding: 1rem;
        background: $color-gold;
        color: #fff;
    }
}

.faq-group {
    margin-bottom: 4rem;
}

.no-results {
    display: none;
}

.faqs-help {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin: 0;
        text-align: center;
        padding-left: 1.5rem;
    }
}