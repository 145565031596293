#location.row {
    margin-bottom: 5rem;
}
.neighbourhood-carousels {
    @include breakpoint(XS) {
        .explore-map-container {
            order: 1 !important;
        }
        .neighbourhood-nav-wrapper {
            padding-top: 1rem;
            padding-bottom: 1rem;
            order: 2;
        }
        .explore-carousels {
            order: 3;
            margin-bottom: 2rem;
        }
    }
}
.blog-nav-container,
.neighbourhood-nav-wrapper {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    .room-nav,
    .explore-nav {
        flex-wrap: nowrap;
        overflow: auto;
        max-width: 100vw;
        justify-content: flex-start;
        padding-left: 1.5rem;
        
        a {
            margin: 0 1rem;
            padding: 1.125rem 1rem;
            display: flex;
            align-items: center;
            text-align: center;
        }
    }
    &:after,
    &:before {
        content: '';
        background: linear-gradient(270deg, #FFFFFF 0%, #FFFFFF 8.89%, rgba(255, 255, 255, 0) 81.25%);
        display: block;
        height: calc(100% - 2rem);
        position: absolute;
        width: 95px;
        pointer-events: none;
        top: 0;
        z-index: 1;
    }
    &:after {
        right: 0;
    }
    &:before {
        left: 0;
        transform: rotate(180deg);
    }
}

.explore-map-carousels {

    .explore-map-container {
        width: 100%;
        position: relative;

        >.row {
            padding-top: 2rem;
        }

        .explore-map {
            position: relative;
            width: 100%;
            height: 38rem;
            @include breakpoint(XS) {
                height: 27rem;
            }
            button {
                &:before {
                    content: none;
                }
            }
        }
        .explore-sights {
            width:70%;
            position:relative;
            padding-right: 60px;
            
            &:after {
                content:'';
                position: absolute;
                right:0;
                height:100%;
                width:60px;
                background:#fff;
                box-shadow:0px 0px 10px 10px rgba(255,255,255,.9);
            }

            .sights-container {
                overflow:scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .bob-right {
                display:flex;
                align-items:center;
                position: absolute;
                right: -30px;
                height: 100%;
                z-index: 1;
                width: 30px;

                svg {
                    transform: rotate(-90deg);
                }
            }            

            a {
                margin-right: 2.5rem;
                min-width:180px;
                flex-shrink: 0;
                @extend %transition;
                .distance {
                    font-style: italic;
                    padding-left: 0.25rem;
                }
                p {
                    margin: 0;
                    width: fit-content;
                }
                svg {
                    margin-right: 0.5rem;
                    color: $color-body;
                    width: 2rem;
                    height: 2rem;
                    
                    * {
                        @extend %transition;
                    }
                }
                &:hover {
                    p {
                        border-bottom: 1px solid $color-gold;
                    }

                    svg {
                        color: $color-gold; 
                    }
                }
                &.active {
                    p {
                        color: $color-gold;
                        border-bottom: 1px solid $color-gold;
                    }
                    svg {
                        * {
                            stroke: $color-gold;
                        }
                    }
                }

                &:last-of-type {
                    padding-right: 20px;
                }
            }
            @include breakpoint(S) {
                display: none !important;
            }
        }

        @include breakpoint(S) {
            order: 2;
            >.row {
                justify-content: center;
            }
        }
    }
    .explore-carousels {
        width: 35%;
        transform: translateX(-6rem);
        position: relative;
        z-index: 2;
        padding-top: 4.75rem;
    }
    @include breakpoint(XS) {
        flex-direction: column;

        .explore-carousels {
            width: 100%;
            transform: translateX(0rem);
            padding-top: 0;
        }
    }
}

.explore-carousel {
    position: relative;
}

.explore-carousel-slide {
    text-align: center;
    position: relative;

    // &:after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 1;
    //     background: rgba(0,0,0,0.7);
    // }    

    .explore-cat {
        font-style: italic;
    }
    h3 {
        margin-bottom: 3rem;
    }
    hr {
        margin-bottom: 2.5rem;
        border-color: $color-white;
    }
    .description {
        margin-bottom: 2rem;
    }
    .distance {
        @extend %uppercase;
    }
    a {
        margin-bottom: 0;
        height: auto !important;
        width: auto !important;
        display: inline-block !important;
        &:before {
            background: $color-white;
        }
    }
    .slide-inner {
        position: relative;
        z-index: 2;
        height: 30rem;
        background: rgba(#000, 0.7);
        padding: 1.625rem 3.125rem;
        padding-top: 3.5rem;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    @include breakpoint(XS) {
        .slide-inner {
            height: 32rem;
            padding: 1.625rem 2.125rem;
            padding-top: 2.5rem;
        }
    }
}

.explore-controls {
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    width: 100%;
    z-index: 3;
    text-align: center;
    color: $color-white;
    color: var(--color-body);

    .controls {
        padding-left: 1rem;
    }

    .caption {
        padding-right: 2rem;
    }

    .explore-nav {
        color: $color-white;
        color: var(--color-body);
        padding: 1rem;

        svg {
            display: block;
            * {
                fill: $color-white;
            }
        }
    }
}

.neighbourhood-nav-wrapper {
    padding-bottom: 6rem;

    @include breakpoint(XS) {
        padding-bottom: 3rem;
        .explore-nav {
            display: flex;
            // flex-wrap: wrap;
        }
    }
}

.explore-nav-wrapper {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h2 {
        .tel {
            margin-top: .75rem;
        }
    }

    @include breakpoint(S) {
        display: block;
        padding-top: 2rem;
        padding-bottom: 2rem;

        h2.no-margin {
            margin-bottom: 2rem;
        }
    }
}