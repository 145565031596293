.single-offers {
    .top-header {
        display: none;
    }
}
.offer {
    @include breakpoint(XS) {
        flex-direction: column;
    }
    .offer-img,
    .offer-info {
        width: 50%;

        @include breakpoint(XS) {
            width: 100%;
        }
    }
    .offer-img {
        position: relative;
        overflow: hidden;

        @include breakpoint(XS) {
            height: 16rem;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .offer-info {
        padding: 5rem 2.5rem;

        @include breakpoint(XS) {
            padding: 1.75rem 2rem;
        }
    }
    .offer-info-inner {
        max-width: 32rem;
        margin: 0 auto;
    }
    &:nth-child(even) {
        .offer-img {
            order: 2;

            @include breakpoint(XS) {
                order: 1;
            }
        }
        .offer-info {
            order: 1;

            @include breakpoint(XS) {
                order: 2;
            }
        }
    }
    .tooltip-trigger {
        cursor: pointer;
        color: $color-active;

        svg {
            margin-left: 0.675rem;
        }
    }
    &.hidden {
        display: none;
    }
}

.offer-filter {
    max-width: 32rem;
    margin: -4rem auto 4rem auto;
    padding: 2rem 3.25rem 2.75rem 3.25rem;
    position: relative;
    z-index: 4;

    @include breakpoint(XS) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding: 1rem 1.3125rem 2rem 1.3125rem;
    }
    .dropdown {
        margin: 0;
        border: 0;
        background: #fff;
    }
    &:after {
        content: "";
        position: absolute;
        bottom: -0.75rem;
        left: 3.25rem;
        right: 3.25rem;
        height: 1.5rem;
        background: $color-gold;

        @include breakpoint(XS) {
            bottom: -0.5rem;
            height: 1.3125rem;
            left: 1.3125rem;
            right: 1.3125rem;
        }
    }
}

.no-offers {
    padding-bottom: 3rem;
}

.offer-detail {
    @include breakpoint(S) {
        flex-direction: column;
    }
    .offer-hero,
    .offer-content {
        width: 50%;

        @include breakpoint(S) {
            width: 100%;
        }
    }
}

.offer-hero {
    img {
        height: 100vh;
        width: 100%;
        top: 4rem;
        position: sticky;

        @include breakpoint(S) {
            position: static;
            height: 26rem;
        }
        @include breakpoint(XS) {
            height: 16rem;
        }
    }
}

.offer-content {
    padding: 6.125rem 4.5rem;

    @include breakpoint(XS) {
        padding: 6rem 2rem;
    }
}

.offer-others {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .offer-others-title {
        max-width: 32rem;
        margin: 0 auto 3rem auto;
    }
}