.newsletter {
    border-top: 1px solid #D7D7E1;
    margin-top: 2rem;
    padding: 2rem 0;
    justify-content: space-between;

    >.img {
        width: 25%;
        height: 230px;
        display: flex;
        justify-content: center;
        position: relative;

        img {
            position: absolute;
            height: 100%;
            width: auto;
        }
    }

    #revinate_success {
        margin-top: 1rem;
        display: none;

        &.show {
            display: block;
        }
    }

    form {
        text-align: center;
        width: 50%;

        h4 {
            font-weight: normal;
            letter-spacing: normal;
            text-transform: none;
            font-family: $font-stack-primary;
        }
        p {

        }
        .input {
            position: relative;
            max-width: 24rem;
            margin: auto;
            margin-top: 2.5rem;
            border-bottom: 2px solid rgba(20,30,70,.25);
            padding-bottom: 10px;
            display: flex;

            input {
                border: none;
                margin: 0;
                padding: 0;
            }
        }
        button {
            margin: 0;
            z-index: 8;
            padding: 0;
            font-size: 12px;
            display: flex;

            svg {
                margin-left: 10px;
            }

            &:before {
                display: none;
            }
        }
    }

    @include breakpoint(XS) {
        flex-direction: column;
        margin-top: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        >svg {
            &:first-of-type {
                display: none;
            }
        }
        >.img {
            order: 2;
            width: 70%;
        }
        form {
            width: 100%;
            margin-bottom: 2rem;
            .input {
                max-width: none;
            }
        }
    }
}
footer{
    padding-bottom: 3.5rem;
}
.footer-menu {
    border-top: 1px solid $color-grey;
    border-bottom: 1px solid $color-grey;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    @include breakpoint(XS) {
        margin-bottom: 2rem;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        a {
            font-family: $font-stack-headings;
            letter-spacing: 0.125em;
            text-transform: uppercase;
            display: inline-block;
            font-weight: bold;
            padding: 0.5rem 0;
            margin: 0 1rem;
            position: relative;
            @include size-S;

            @include breakpoint(XS) {
                margin: 0.75rem 1rem;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 2px;
                background: $color-gold;
                width: 0;
                @extend %nav-transition;
            }
            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }
    }
}

.preferred {
    display: block;

    @include breakpoint(XS) {
        margin-bottom: 1.5rem;
    }
    svg {
        display: block;

        @include breakpoint(XS) {
            margin: 0 auto;
        }
    }
}

.footer-bottom {
    margin-bottom: 1.5rem;

    @include breakpoint(XS) {
        flex-direction: column;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 44px;

        p {
            margin-bottom: 1.5rem;
            order: 1;
            line-height: 2.5;
        }
        .preferred {
            order: 2;
        }
        .social-links {
            justify-content: center;
            order: 3;
        }
    }
    > div,
    > a,
    > p {
        flex-grow: 1;
        flex-basis: 0;
    }
    > .logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.5rem;

        > * {
            width: clamp(3rem, 5vw, 4.5rem);
            aspect-ratio: 1;
            display: flex;
        }

        img {
            object-fit: contain;
            object-position: center;
        }

        @include breakpoint(XS) {
            margin-bottom: 1.5rem;
        }
    }
}

.inpage-modal,
.fancybox-slide .inpage-modal {
    text-align: center;
    width: 70vw;
    max-width: 56rem;
    padding: 5rem;
    background: #fff;
    background: var(--color-bg);
    background-size: 100%;
    margin: 5rem auto;

    @include breakpoint(S) {
        width: 90vw;
        padding: 5rem;
    }
    @include breakpoint(XS) {
        padding: 3rem 2rem;
    }
    ul {
        width: 50%;
        float: left;
        text-align: left;
        padding: 0 1rem;

        @include breakpoint(S) {
            width: 100%;
            float: none;
            padding: 0;
        }
        li {
            font-size: 0.875rem;

            &:before {
                background: $color-gold;
                background: var(--color-active);
                width: 6px;
                height: 6px;
                top: 0.5rem;
            }
        }
    }
}

.fancybox-button--fullscreen {
    display: none !important;
}

body .fancybox-button--close {
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    border-radius: 100%;
    background: #fff;
    background: var(--color-reverse);
    display: block;
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 11;
    font-size: 1rem;
    @extend %transition;

    @include breakpoint(XS) {
        top: 1.25rem;
        left: 1.25rem;
    }
    &:before,
    &:after {
        display: block;
        background: $color-gold;
        background: var(--color-body);
        position: absolute;
        left: 1.125rem;
        top: 2.125rem;
        width: 2.25em;
        border-radius: 2px;
        opacity: 1;
        height: 4px;
        @extend %transition;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        background: #fff;
        background: var(--color-bg);
        
        &:before,
        &:after {
            background: $color-purple;
            background: var(--color-body);
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}

.mob-controls {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    overflow: hidden;
    background: #fff;
    @extend %transition;

    .group-select {
        width: 50%;
        padding: 0.5rem;
        border-left: 1px solid rgba($color-purple, 0.25);

        &.stretch {
            flex-grow: 1;
        }
        .dropdown {
            border: 0;
        }
    }
    .dropdown {
        margin: 0;
    }
    .faqs-search {
        padding: 0.5rem 1rem 0.5rem 0.5rem;
        width: 50%;
        margin: 0;

        svg {
            left: 0.5em;
        }
        input {
            margin: 0;
            padding: 1rem 0 1rem 2.5rem;
        }
    }
    @include breakpoint(XS) {
        display: flex;
    }
    &.focus {
        width: 160%;

        .dropdown {
            select,
            label {
                opacity: 0.25;
            }
        }
    }
    .back-to-top {
        border: 0;
        border-radius: 0;
        min-width: 0;
        padding: 0.75em 1em 0.75em 1.5em;
        display: flex;
        align-items: center;
    }
}