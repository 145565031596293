.page-content {
    padding-top: 4rem;
    padding-bottom: 4rem;
    
    .page-content-inner {
        max-width: 52rem;
        margin: 0 auto;
    }
}

.error404 {

    .builder-elements {
        padding-top: 10em;
    }
}

.ajax-loader {
    display: block;
}

div.wpcf7 .ajax-loader {
    display: block;
    margin: 0 auto;
}