//Font Sizes

@mixin size-XS() {
    font-size: 0.75rem;
    line-height: $unit * 2;
  
    @include breakpoint(M) {
      font-size: 0.71428571rem;
      line-height: $unit * 2;
    }
}
  
@mixin size-S() {
    font-size: 0.7778rem;
    line-height: $unit * 2.5;
  
    @include breakpoint(M) {
        font-size: 0.85714286rem;
        line-height: $unit * 2.5;
    }
}
  
@mixin size-M() {
    font-size: 1rem;
    line-height: $unit * 3;
  
    @include breakpoint(M) {
        font-size: 1rem;
        line-height: $unit * 3;
    }
}
  
  @mixin size-L() {
    font-size: 1.3125rem;
    line-height: $unit * 3.5;
  
    @include breakpoint(M) {
        font-size: 1.28571429rem;
        line-height: $unit * 3.5;
    }
}
  
@mixin size-XL() {
    font-size: 1.75rem;
    line-height: $unit * 4;
  
    @include breakpoint(M) {
        font-size: 1.5rem;
        line-height: $unit * 4;
    }
}
  
@mixin size-2XL() {
    font-size: 2.375rem;
    line-height: $unit * 5.5;
  
    @include breakpoint(M) {
        font-size: 1.92857143rem;
        line-height: $unit * 5;
    }
}
  
@mixin size-3XL() {
    font-size: 3.1875rem;
    line-height: $unit * 7;
  
    @include breakpoint(M) {
        font-size: 2.42857143rem;
        line-height: $unit * 6;
    }
}

.size-xs {
    @include size-XS;
}

.size-s {
    @include size-S;
}

.size-m {
    @include size-M;
}

.size-l {
    @include size-L;
}

.size-xl {
    @include size-XL;
}

.size-2xl {
    @include size-2XL;
}

.size-3xl {
    @include size-3XL;
}