// Fonts
$font-stack-headings: AvenirLTStd, Avenir, Montserrat, Corbel, 'URW Gothic', source-sans-pro, sans-serif;
$font-stack-primary: Neva, Charter, 'Bitstream Charter', 'Sitka Text', Cambria, serif;

$font-stack-system: $font-stack-primary;
$font-stack-monospace: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

// General Breakpoints
$breakpoints: (
    'XS': ( max-width:  40em ),
    'XS-up': ( min-width: 641px ),
    'S': ( max-width:  48em ),
    'S-up': ( min-width: 769px ),
    'M': ( max-width:  64em ),
    'M-up': ( min-width:  64em ),
    'ML': ( max-width:  75em ),
    'ML-up': ( min-width:  75em ),
    'L': ( max-width: 91em ),
    'XL': ( min-width: 91em ),
    'XXL': ( min-width: 105em ),
    'XXXL': ( min-width: 112em )
);
                     