.find-us {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include breakpoint(XS) {
        padding-bottom: 0;
    }
    .location-block .map-container {
        min-height: calc(100vh - 5rem);

        @include breakpoint(S) {
            min-height: calc(30vh - 5rem);
        }
    }
    .directions-tabs-wrapper {
        padding-top: 5rem;

        @include breakpoint(S) {
            padding-top: 0;
        }
        .directions-tab {
            padding-top: 2.875rem;

            @include breakpoint(S) {
                padding-top: 1.5rem;
                overflow: scroll;
                height: calc(50vh - 2.5rem);
                padding-bottom: 1rem;

                .cta .cta-inner {
                    padding: 0;
                }
            }
            @include breakpoint(XS) {
                min-height: 0;
            }
        }
        .local-next {
            right: auto;
            left: calc(100% - 2.75rem);
    
            @include breakpoint(S) {
                left: auto;
                right: 2rem;
            }
        }
    }
    @include breakpoint(XS) {
        .directions-carousel .cta {
            height: calc(50vh - 7rem);

            p,
            hr {
                display: none;
            }
        }
    }
    .directions-carousel {
        margin-right: -4.5rem;

        @include breakpoint(S) {
            margin-right: 0;
        }
    }
}

.location-block {
    display: flex;
    align-items: center;

    @include breakpoint(S) {
        display: block;
    }
    .directions {
        width: 50%;

        @include breakpoint(S) {
            width: 100%;
        }
    }
    .map-container {
        height: 40em;
        width: 100%;
        position: relative;
        z-index: 2;

        @include breakpoint(S) {
            height: 28em;
        }
        @include breakpoint(XS) {
            height: 18em;
        }
    }
    &.in-page-builder {
        // @include breakpoint(XS) {
        //     display: none;
        // }
    }
}

.cta.mobile-location-block {
    display: none;

    // @include breakpoint(XS) {
    //     display: flex;
    // }
}

.directions-tabs-wrapper {
    width: 50%;
    padding: 0rem 0em 0 4.5em;

    @include breakpoint(S) {
        width: 100%;
        padding: 0.5rem 0 0 0;
    }
    .directions-tabs {
        padding-right: 4.5em;
        @include breakpoint(XS) {
            padding-right: 0;
        }
    }
    .directions-tab {
        margin-top: 3.875rem;
        position: relative;
        // min-height: 25rem;

        .accordion {
            @include breakpoint(S) {
                padding: 0 6.75rem;
            }
            @include breakpoint(XS) {
                padding: 0 2rem;
            }

            .accordion-title {
                opacity: 0.5;
                &.open,
                &:hover {
                    opacity: 1;
                }
            }
        }
        @include breakpoint(S) {
            padding-left: 1.375em;
            padding-right: 1.375em;

            &.tab-local {
                padding-right: 0;
            }
        }
    }
    .local-next {
        top: 50%;
        transform: translateY(-50%);
        right: -8.5em;
        background: rgba(61,64,69,0.25);
        background-blend: multiply;

        @include breakpoint(S) {
            right: 2rem;
        }
    }
    .tab-juliani {
        text-align: center;

        .background-pin {
            position: absolute;
            opacity: 0.05;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        .h3 {
            line-height: 1.5;
        }
    }
}

.directions-carousel {
    margin-right: -9.5rem;

    @include breakpoint(S) {
        margin-right: 0;
        padding-left: 1.5em;
    }
    @include breakpoint(XS) {
        padding-left: 0;
    }
    .cta {
        width: calc(50vw - 15em);
        height: 30em;
        display: flex !important;

        @include breakpoint(S) {
            width: 30em;
        }
        @include breakpoint(XS) {
            width: 20em;
            height: 25em;
            margin: 0.5rem;
        }
    }
}

.in-page-nav.directions-tabs-nav {
    a {
        margin: 0 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
    @include breakpoint(XS) {
        display: flex;
        flex-wrap: wrap;
    }
}

// .inpage-nav,
// .directions-tabs-nav {
//     text-align: center;
//     display: flex;
//     justify-content: center;

//     @include breakpoint(S) {
//         border-bottom: 1px solid rgba($color-body, 0.25);
//     }
//     a {
//         color: $color-body;
//         opacity: 0.25;
//         text-transform: uppercase;
//         letter-spacing: 3px;
//         font-size: 0.875em;
//         margin: 0 1.5rem;
//         padding: 0.5rem 0;
//         position: relative;

//         @include breakpoint(M) {
//             margin: 0 0.75rem;
//         }
//         @include breakpoint(S) {
//             padding: 1rem 0;
//             margin: 0 1.5rem;
//         }
//         @include breakpoint(XS) {
//             margin: 0 0.75rem;
//         }
//         &:after {
//             content: "";
//             position: absolute;
//             left: 50%;
//             transform: translateX(-50%);
//             width: 0;
//             bottom: 0;
//             height: 2px;
//             background: $color-body;
//             @extend %transition;
//         }
//         &.active,
//         &:hover {
//             color: $color-body;
//             opacity: 1;

//             &:after {
//                 width: 100%;
//             }
//         }
//     }
// }

.directions-tab {
    @extend %transition;

    &.filtered {
        display: none;
    }
    &.fade-out {
        opacity: 0;
    }
}