.single-rooms {
    .top-header {
        display: none;
    }
    .secondary-header {
        top: 0;
    }
}

.room-modal {
    background: #fff;

    @include breakpoint(S) {
        overflow: auto;
    }
}
.room-nav {
    display: inline-flex;
}
.rooms-list {
    // margin: 0 -0.75em;
    
    @include breakpoint(S) {
        flex-direction: column;
    }
    @include breakpoint(XS) {
        margin: 0;
    }
    .sub-offers {
        padding-bottom: 2rem;
    }    
    .room-showcase {
        padding-bottom: 4rem;

        .room-details {
            padding: 0 2rem;
            
            @include breakpoint(XS) {
                padding: 0;
            }

            hr {
                margin-bottom: 2rem;
            }

            .room-content {
                margin-bottom: 2rem;
                //min-height: 8rem;
            }

            p.buttons {
                gap: 2rem;
                .button {
                    margin: 0.5rem 4.5rem 0.5rem 0;
                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include breakpoint(S) {
                    .button {
                        margin: 0.5rem 0.5rem 0.5rem 0;
                    }
                }
                @include breakpoint(XS) {
                    .button {
                        &:last-child {
                            margin-right: 0.5rem;
                        }
                    }
                }                
            }
        }
    }
}
.room-img {
    display: flex;
    position: relative;    
    padding-bottom: 4.5rem;

    @include breakpoint(XS) {
        flex-direction: column;
    }
    .img-slide {
        position: relative;
    }
    .gallery-modal-trigger {
        position: absolute;
        padding: 0.5em;
        bottom: 0;
        z-index: 1;
        background: rgba($color-body, 0.5);
        opacity: 0;

        // &:hover {
        //     background: $color-body;
        // }
        svg {
            display: block;
        }
        @include breakpoint(S) {
            bottom: auto;
            top: 0;
            left: auto;
            right: 0;
        }
    }
    &:hover {
        .gallery-modal-trigger {
            opacity: 1;
        }
    }
    .content {
        width: 45%;
        padding: 0 0 0 7.25em;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(M) {
            padding: 0 0 0 5em;
        }
        @include breakpoint(S) {
            padding: 0 0 0 3em;
        }
        @include breakpoint(XS) {
            padding: 6.5rem 0 2rem 0;
            margin: 0;
            width: 100%;
            text-align: center;
            flex-basis: auto;

            hr {
                margin-left: auto;
                margin-right: auto;
            }
            .buttons {
                justify-content: center;
                margin-bottom: 0;
            }
        }
        &.top-align {
            padding-top: 1em;
            padding-bottom: 7.5em;

            @include breakpoint(XS) {
                padding-bottom: 2em;
            }
        }
        .content-inner {
            width: 100%;
            max-width: 32rem;
            display: flex;
            flex-direction: column;

            .heading {
                position: relative;
        
                .title-illustration {
                    position: absolute;
                    right: -10%;
                    width: 55%;
                    bottom: -2rem;
                    z-index: -1;

                    img {
                        margin-left: auto;
                    }
                }
            }            

            @include breakpoint(XS) {
                padding-top: 0;
                align-items: center;
                max-width: none;

                hr {
                    +div {
                        margin-bottom: 1rem;
                    }
                }

                > .button {
                    margin-top: -1.5rem;
                }

                .heading {

                    .title-illustration {
                        position: static;
                        width: 75%;
                        margin: auto;
                        margin-bottom: 2rem;
                    }
                }
            }          
        }
    }
    .img {
        width: 100%;
        position: relative;
        min-height: 22.5em;
        
        @include breakpoint(XS) {
            min-height: 20em;
            margin-left: -2.5rem;
            margin-right: -2.5rem;
            width: calc(100% + 5rem);

            .slick-dots {
                display: none !important;
            }
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .slick-list,
        .slick-track {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
    .image-carousel-controls {

        @include breakpoint(ML) {
            padding-left: 2rem;
        }
        @include breakpoint(XS) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
    &.text-image {
        .image-carousel-controls {
            padding-left: 0;
            padding-right: 5rem;

            @include breakpoint(ML) {
                padding-right: 2rem;
            }
            @include breakpoint(XS) {
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }
        }
        .content {
            order: 1;
            padding: 0 5.25em 0 0;

            @include breakpoint(M) {
                padding: 0 5em 0 0;
            }
            @include breakpoint(S) {
                padding: 0 3em 0 0;
            }
            @include breakpoint(XS) {
                padding: 5rem 0 2rem 0;
                display: block;
                margin: 0;
                order: unset;
            }
        }
        .img {
            order: 2;

            @include breakpoint(XS) {
                order: unset;
                margin-left: -2.5rem;
                margin-right: -2.5rem;
                width: calc(100% + 5rem);
            }
        }
    }
    &.mob-img-bottom {
        @include breakpoint(XS) {
            .content {
                order: 1;
            }
            .img {
                order: 2;
            }
        }
    }
}

.image-carousel-controls {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    width: 100%;

    &.single-img {
        top: calc(100% + 2.5rem);  
    }

    a {
        padding: 1rem;

        svg {
            display: block;
        }
    }
    .caption {
        span {
            padding-right: 1rem;
        }
        @include breakpoint(XS) {
            display: flex;
            justify-content: center;
            flex-direction: column;            
            span {
                padding: 0;
                &:first-of-type {
                    order: 2;
                }
                &:last-of-type {
                    order: 1;
                }                
            }
        }
    }
    @include breakpoint(XS) {
        .caption {
            position: absolute;
            left: 25%;
            width: 50%;
            text-align: center;
        }
        .controls {
            width: 100%;
            justify-content: space-between;
        }
    }
}

.js-image-carousel {

    .slick-track {

        .img-slide {
            @include breakpoint(XS) {
                
            }
        }
    }
}

/* Room Single */
.back {
    letter-spacing: .125em;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 1rem;
    font-family: $font-stack-headings;

    svg {
        margin-right: 0.5rem;
    }
}

.rooms-list.room-single {

    .room-showcase {
        
        .back {
            letter-spacing: .125em;
            font-weight: bold;
            display: inline-block;
            margin-bottom: 1rem;
            font-family: $font-stack-headings;

            svg {
                margin-right: 0.5rem;
            }
        }
        .room-details {
            padding-left: 4rem;
            padding-right: 4rem;
            padding-top: 8.5rem;
            width: 40%;
            
            .room-features {
                >div {
                    border-bottom: 1px solid $color-grey;
                    margin-bottom: 1.5rem;
                    &:last-of-type {
                        border-bottom: none;
                    }
                }
                ul {
                    li {
                        margin-bottom: .25rem;
                        &:before {
                            height: 1px;
                            top: 8px;
                        }
                    }
                }
            }
            p.buttons {

                .button {
                    margin: 0.5rem 2rem 0.5rem 0;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .room-img {
            width: 60%;
            padding: 0;
            .image-carousel-controls {
                top: auto;
                bottom: 1rem;
                padding-left: 2rem;
                padding-right: 2rem;
                z-index: 2;
                .controls {
                    a {
                        svg {
                            path {
                                fill: $color-white;
                            }
                        }
                    }
                }
                .caption {
                    color: $color-white;
                }
            }
        }
        @include breakpoint(M) {
            max-height: none;
            flex-wrap: wrap;

            .room-details {
                order: 2;
                width: 100%;
                padding-top: 4.5rem;
                max-height: none;
            }
            .room-img {
                width: 100%;
            }
        }
    }

    @media (min-aspect-ratio: 1/1) {
        .room-showcase {
            position: relative;
            min-height: 100vh;

            .room-details {
                width: 45%;
                overflow: scroll;
                max-height: 100vh;
            }

            .room-img {
                width: 55%;
                top: 0;
                right: 0;
                height: 100%;
                padding: 0;
                position: absolute;
                height: 100vh;

                img {
                    width: 100%;
                    position: sticky;
                    height: 100vh;
                    top: 0;
                }
            }
        }
    }
}